<!--
File: PrintTitleList.vue
Description: form for generating printed version of the Titlelist.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout" style="align-items: center; justify-content: flex-start; width: auto;">
        <div class="md-layout-item md-small-size-20 md-size-15">
          <BaseDropdown :label="$t('Saved worklists')" v-model="selectedWorklist" :items="savedWorksList"
            :displayField="'year'" :valueField="'work_list_id'" @input="reloadTreatmentsList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" data-vv-name="selectedRegion"
            :initial_value="selectedRegion" @input="reloadTreatmentsList" />
        </div>
        <div v-show='showPrintButton' class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('Show as')" v-model="showAmounts" :items="amounts" :displayField="'name'"
            :valueField="'id'" />
        </div>
        <div v-show='showPrintButton' class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-raised md-success" @click="print">{{ $t('buttons.print') }}</md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-show="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table v-if="dataIsLoaded && total > 0" class="paginated-table table-striped table-hover" :value="treatmentsList"
        md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <!--md-table-row slot="columns" slot-scope="{item}" />
          <md-table-head style="max-width: 30px;">{{ $t('No') }}</md-table-head>
      </md-table-row-->

        <md-table-row v-show="dataIsLoaded">
          <md-table-head style="max-width: 30px;">{{ $t('stdCols.id') }}</md-table-head>
          <md-table-head style="min-width: 30%;">{{ $t('tunnel.road_name') }} </md-table-head>
          <md-table-head>{{ $t('label.units') }} </md-table-head>
          <md-table-head>{{ $t('label.quantity') }} </md-table-head>
          <md-table-head>{{ $t('label.expected_outcome') }} </md-table-head>
          <md-table-head>{{ $t('tables.cost') }} </md-table-head>
          <md-table-cell>               {{ $t('road_network.dep') }}</md-table-cell>
        </md-table-row>

        <!-- Цикл по группам -->
        <template v-show="dataIsLoaded" v-for="(group, groupName) in groupedData">
          <!-- Строка с названием группы -->
          <md-table-row style="background-color: rgb(255, 255, 0);">
            <md-table-cell style="max-width: 30px;"> &nbsp; </md-table-cell>
            <md-table-cell colspan="5"><strong>{{ groupName }}</strong></md-table-cell>
            <md-table-cell> &nbsp; </md-table-cell>
          </md-table-row>

          <!-- Внутренний цикл для каждой строки в группе -->
          <!--md-table-row slot="md-table-row" slot-scope="{item}"-->
          <md-table-row v-show="dataIsLoaded" v-for="(item, index) in group" :key="item.treatment_id">
            <md-table-cell style="max-width: 30px;">{{ index + 1 }}</md-table-cell>
            <!-- Нумерация начинается с 1 для каждой группы -->
            <md-table-cell style="min-width: 40%;">{{ item.section_description }}</md-table-cell>
            <md-table-cell>{{ item.unit_description }}</md-table-cell>
            <md-table-cell md-numeric>{{ item.units | numFormat(0) }}</md-table-cell>
            <md-table-cell>{{ item.expected_outcome }}</md-table-cell>
            <md-table-cell md-numeric>{{ item.cost / showAmounts | numFormat }}</md-table-cell>
            <md-table-cell>{{ item.deu }}</md-table-cell>
          </md-table-row>

          <!-- Итоговая строка для группы -->
          <md-table-row v-show="dataIsLoaded" style="background-color: rgb(204, 221, 255);">
            <md-table-head style="max-width: 30px;"> &nbsp; </md-table-head>
            <md-table-head style="min-width: 40%;">{{ $t('Subtotal') }}: </md-table-head>
            <md-table-head>&nbsp;</md-table-head>
            <md-table-head md-numeric>{{ groupTotals[groupName].totalUnits | numFormat(0) }}</md-table-head>
            <md-table-head>&nbsp;</md-table-head>
            <md-table-head md-numeric>{{ groupTotals[groupName].totalCost / showAmounts | numFormat }}</md-table-head>
            <md-table-head>&nbsp;</md-table-head>
          </md-table-row>
        </template>

        <!-- Routine Maintenance -->
        <md-table-row v-show="dataIsLoaded">
          <md-table-cell colspan="6"><strong>Routine Maintenance</strong></md-table-cell>
          <md-table-cell> &nbsp; </md-table-cell>
        </md-table-row>

        <md-table-row v-show="dataIsLoaded">
          <md-table-cell style="max-width: 30px;">1</md-table-cell>
          <md-table-cell colspan="5"><strong>1 Current repairs total, including:</strong></md-table-cell>
          <md-table-cell> &nbsp; </md-table-cell>
        </md-table-row>

        <md-table-row v-show="dataIsLoaded" v-for="(item) in groupTotalsRM" :key="item.treatment_id"
          style="background-color: rgb(255, 255, 0);">
          <md-table-cell style="max-width: 30px;">&nbsp; </md-table-cell>
          <md-table-cell style="min-width: 40%;">{{ item.name }}</md-table-cell>
          <md-table-cell>{{ item.unit_description }}</md-table-cell>
          <md-table-cell md-numeric>{{ item.totalUnits | numFormat(0) }}</md-table-cell>
          <md-table-cell>{{ item.expected_outcome }}</md-table-cell>
          <md-table-cell md-numeric>{{ item.totalCost / showAmounts | numFormat }}</md-table-cell>
          <md-table-cell>{{ item.deu }}</md-table-cell>
        </md-table-row>
        <!--md-table-row style="background-color: rgb(204, 221, 255);">
            <md-table-head style="max-width: 30px;"> &nbsp; </md-table-head>
            <md-table-head style="min-width: 40%;">{{ $t('Subtotal') }}: </md-table-head>
            <md-table-head>&nbsp;</md-table-head>
            <md-table-head md-numeric>{{ groupTotalsRM.totalUnits | numFormat(0) }}</md-table-head>
            <md-table-head>&nbsp;</md-table-head>
            <md-table-head md-numeric>{{ groupTotalsRM[groupName].totalCost / showAmounts | numFormat }}</md-table-head>
          </md-table-row-->

        <!-- Общий итог -->
        <md-table-row v-show="dataIsLoaded">
          <md-table-head style="max-width: 30px;"> &nbsp; </md-table-head>
          <md-table-head style="min-width: 40%;">{{ $t('label.total') }}: </md-table-head>
          <md-table-head colspan="3">&nbsp;</md-table-head>
          <md-table-head>{{ overallTotal / showAmounts | numFormat }} </md-table-head>
          <md-table-cell> &nbsp; </md-table-cell>
        </md-table-row>
      </md-table> </md-card-content>
  </md-card>
</template>

<script>
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
import { mapState, mapGetters } from 'vuex'
import { numFormat } from "@/store/refdata/format_helpers"

export default {
  name: 'print-title-list-form',
  data() {
    return {
      showSpinner: true,
      selectedWorklist: null,
      selectedRegion: null,
      selectedPlanForAssignment: null,
      selectedPlan: null,
      selectedBudget: null,
      selectedItems: [],
      dataIsLoaded: false,

      showAmounts: 1000000,
      amounts: [
        { id: 1, name: this.$t("soms") },
        { id: 1000, name: this.$t("Thousands") },
        { id: 1000000, name: this.$t("Millions") }
      ],
      regionDescr: null,
    }
  },

  props: {
    step: { default: 'first', type: String },
  },


  components: {
    RegionsDropdown,
    BaseDropdown
  },

  mounted() {
    this.toggleSpinner(true)

    // Reset the lists in the store if they were filled previouly
    this.$store.dispatch('RESET_WORK_LISTS')
    this.$store.dispatch('RESET_TREATMENTS')

    this.reloadWorksLists()
    this.toggleSpinner(false)
  },

  methods: {
    toggleSpinner(state) {
      this.showSpinner = state
    },
    reloadWorksLists() {
      const params = {
        is_approved: 1
      }
      this.$store.dispatch('LOAD_WORKS_LISTS', {}).then((res) => {
        console.log(res)
      })
    },
    async reloadTreatmentsList(region_id, regionDescr) {
      let returnZeroRows = !this.selectedRegion || !this.selectedWorklist
      if (returnZeroRows) {
        this.$store.dispatch('RESET_TREATMENTS')
        this.toggleSpinner(false)
        return
      }

      // get Region description needed for showing in the printed form
      if (regionDescr) this.regionDescr = regionDescr

      this.dataIsLoaded = false
      this.toggleSpinner(true)
      this.selectedItems = []
        await this.$store.dispatch('LOAD_SECTIONS_LIST', {region_id: this.selectedRegion})
      const params = {
        work_list_id: this.selectedWorklist,
        region_id: this.selectedRegion,
        //is_plan_assigned: this.step === 'first' ? 0 : 1,
        is_budget_assigned: 1,
        //is_approved: 0,
        //plan_id: this.step === 'second' ? this.selectedPlan : null,
        //budget_id: this.step === 'third' ? this.selectedBudget : null,
      }
      this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {

        //console.log('groupedData', this.groupedData)
        //console.log('groupTotalsRM', this.groupTotalsRM)
        //        console.log('groupedDataRM', this.groupedDataRM)
        //        console.log('groupTotalsRM', this.groupTotalsRM)

        this.dataIsLoaded = true
        this.toggleSpinner(false)
      })
    },

    print() {
      this.showSpinner = true
      const today = new Date().toLocaleDateString()
      let prtHtml = `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
          <h4 style='text-align:center'>${this.$t('route.title_list')}
          ${this.$t('label.as_on')} ${today},&nbsp;${this.$t('label.from_region', { name: this.regionDescr })}</h4>`

      let tableHeaders = `<tr>
          <th>${this.$t('stdCols.id')}</th>
          <th>${this.$t('tunnel.road_name')}</th>
          <th>${this.$t('label.units')}</th>
          <th>${this.$t('label.quantity')}</th>
          <th>${this.$t('label.expected_outcome')}</th>
          <th>${this.$t('tables.cost')}</th>
          <th>${this.$t('road_network.dep')}</th>
        </tr>`

      let tableRows = ''
      for (const [groupName, group] of Object.entries(this.groupedData)) {
        // Строка с названием группы
        tableRows += `<tr>
            <td>&nbsp;</td>
            <td colspan='5'><strong>${ groupName }</strong></td>
            <td>&nbsp;</td>
          </tr>`

        //Внутренний цикл для каждой строки в группе
        group.forEach((row, index) => {
          tableRows += `<tr>
            <td class='numCell'>${ index + 1 }</td>
            <td>${ row.section_description }</td>
            <td>${row.unit_description}</td>
            <td class='numCell'>${numFormat(row.units)}</td>
            <td>${ row.expected_outcome }</td>
            <td class='numCell'>${numFormat(row.cost / this.showAmounts)}</td>
            <td>${ row.deu }</td></tr>`
        })

        // Итоговая строка для группы
        tableRows += `<tr>
            <td>&nbsp;</td>
            <td><strong>${this.$t('Subtotal')}</strong></td>
            <td>&nbsp;</td>
            <td class='numCell'><strong>${numFormat(this.groupTotals[groupName].totalUnits)}</strong></td>
            <td>&nbsp;</td>
            <td class='numCell'><strong>${numFormat(this.groupTotals[groupName].totalCost / this.showAmounts)}</strong></td>
            <td>&nbsp;</td></tr>`
          }

      // Routine Maintenance
      tableRows += `<tr>
            <td colspan='5'><strong>Routine Maintenance</strong></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colspan='5'><strong>1 Current repairs total, including:</strong></td>
            <td>&nbsp;</td>
          </tr>`

      for (const [row] of Object.entries(this.groupTotalsRM)) {
        tableRows += `<tr>
            <td>&nbsp;</td>
            <td>${ row.name }</td>
            <td>${row.unit_description}</td>
            <td class='numCell'>${ numFormat(row.totalUnits) }</td>
            <td>${ row.expected_outcome }</td>
            <td class='numCell'>${numFormat(row.totalCost / this.showAmounts)}</td>
            <td>&nbsp;</td>
      </tr>`
      }

      // Общий итог
      tableRows += `<tr>
          <td>&nbsp;</td>
          <td><strong>${ this.$t('label.total') }</strong></td>
          <td colspan='3'>&nbsp;</td>
          <td class='numCell'>${numFormat(this.overallTotal / this.showAmounts)}</td>
          <td>&nbsp;</td>
        </tr>`

      const PrintWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      PrintWin.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 11px; }
                th, td { border: 1px solid black; padding: 5px; overflow: visible; white-space: normal; word-wrap: break-word; }
                .numCell { text-align: right; padding-right: 5px; }
              </style>
            </head>
            <body>${prtHtml}
                    <table>
                      <thead>${tableHeaders}</thead>
                      <tbody>${tableRows}</tbody>
              </table>
            </body>
          </html>`
      )

      PrintWin.document.close();
      this.showSpinner = false
      PrintWin.focus();
      PrintWin.print();
      PrintWin.close();
    },

  },
  computed: {
    ...mapState({
      savedWorksList: (state) => state.TitleList.worksLists,
      treatmentsList: (state) => state.TitleList.treatmentsList,
      workCategoriesList: (state) => state.Dropdowns.work_categories,
      
      sectionsList: (state) => state.Sections.list

      //budgetList: (state) => state.Budgets.list
    }),
    ...mapGetters([]),

    total() {
      return this.treatmentsList.length
    },
    showPrintButton() {
      return this.total > 0
    },

    // Группировка данных по полю 'group'
    groupedData() {
      // remove the block below after Andrei implements in the API
      // Создаем маппинг для привязки данных по fk_section
      const sectionMap = this.sectionsList.reduce((map, section) => {
        map[section.section_id] = `${this.$t('road_network.dep')}-${section.deu}`
        return map;
      }, {});

      let res = this.treatmentsList
        // change to counting by number of treatments later
        .filter(el => el.fk_work_category !== 4)
        .sort((a, b) => {
          const descA = a.treatment_type_description || ''
          const descB = b.treatment_type_description || ''
          return descA.localeCompare(descB, undefined, { sensitivity: 'base' });
        })

        .reduce((groups, row) => {
            const group = row.treatment_type_description
          if (!groups[group]) {
            groups[group] = [];
          }

          groups[group].push({
            ...row,
            // remove the line below after Andrei implements in the API
            deu: sectionMap[row.fk_section] || null  // Добавляем привязанные данные
          });
          return groups;
        }, {})
      return res  //
    },
    groupedDataRM() {
      let res = this.treatmentsList
        //.filter(el => // change to fk_work_category later
        //['Patching', 'Local repair', 'Crack sealing', 'Местный ремонт', 'Заливка трещин', 'Ямочный ремонт']
        //.includes(el.treatment_type_description))
        .filter(el => el.fk_work_category === 4)
        .reduce((groups, row) => {
          const group = row.treatment_type_description  //'Routine Maintenance'
          if (!groups[group]) {
            groups[group] = [];
          }
          groups[group].push(row);
          return groups;
        }, {})
      return res  //
    },
    // Подсчет итогов для каждой группы
    groupTotals() {
      return Object.keys(this.groupedData).reduce((totals, groupName) => {
        totals[groupName] = {
          totalCost: this.groupedData[groupName].reduce((sum, row) => sum + row.cost, 0),
          totalUnits: this.groupedData[groupName].reduce((sum, row) => sum + row.units, 0)
        }
        return totals
      }, {});
    },
    groupTotalsRM() {
      return Object.keys(this.groupedDataRM).reduce((totals, groupName) => {
        totals[groupName] = {
          name: groupName,
          totalCost: this.groupedDataRM[groupName].reduce((sum, row) => sum + row.cost, 0),
          totalUnits: this.groupedDataRM[groupName].reduce((sum, row) => sum + row.units, 0)
        }
        return totals
      }, {});
    },
    // Подсчет общего итога по всей таблице
    overallTotal() {
      return this.treatmentsList.reduce((sum, row) => sum + row.cost, 0);
    },
  },
  watch: {
    step(newValue, oldValue) {
      if (newValue !== oldValue && this.step === 'fifth')
        this.selectedRegion = null
    }
  }
};
</script>
<style scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>